module controllers {
    export module logistics {
        interface ICommercialInvoiceSundryItemScope extends ng.IScope {
        }
        interface ICommercialInvoiceSundryItemParams extends ng.ui.IStateParamsService {
            cisId: number;
            comId: number;
        }

        export class commercialInvoiceSundryItemCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$anchorScroll', 'bsLoadingOverlayService', '$state', '$timeout', "$stateParams",
                "commercialInvoiceSundryItemService", "isCostInvoice", "sundryItemService", "costModelElementService", "vatRateService",
                "classificationValueService", "unitOfMeasureService", "commercialInvoiceService", "entityService", "warehouseService",'isInbound',];

            ciSundryItem: interfaces.logistics.ICommercialInvoiceSundryItem;

            breadCrumbDesc: string;

            constructor(private $scope: ICommercialInvoiceSundryItemScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private bsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private $stateParams: ICommercialInvoiceSundryItemParams,
                private commercialInvoiceSundryItemService: interfaces.logistics.ICommercialInvoiceSundryItemService,
                private isCostInvoice: boolean,
                private sundryItemService: interfaces.master.ISundryItemService,
                private costModelElementService: interfaces.costing.ICostModelElementService,
                private vatRateService: interfaces.master.IVatRateService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                private commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                private entityService: interfaces.applicationcore.IEntityService,
                private warehouseService: interfaces.master.IWarehouseService,
                public isInbound: boolean,
                ) {

                    //if we are coming via the Manifest, it will tell us the isInbound flag based on Manifest route
                    if (this.$state.params['manIsInbound']) {                      
                      this.isInbound = this.$state.params['manIsInbound'];
                    }               

                bsLoadingOverlayService.wrap({
                    referenceId: 'ci.sun.update'
                }, () => {
                    return this.loadCommercialInvoiceSundryItem();
                });
            }

            loadCommercialInvoiceSundryItem() {
                return this.commercialInvoiceSundryItemService.get(this.$stateParams.cisId, this.isCostInvoice, this.$stateParams.comId).get((result) => {
                    this.ciSundryItem = result;

                    if (this.ciSundryItem.SundryItem) {
                        this.breadCrumbDesc = this.ciSundryItem.SequenceNumber + " - " + this.ciSundryItem.SundryItem.Description;
                    }
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadSundryItem(searchText: string) {
                if (this.ciSundryItem) {
                    return this.sundryItemService.getDropdownList(this.ciSundryItem.OwnerEntityId,this.isInbound, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadCostModelElement(searchText: string) {
                if (this.ciSundryItem) {
                    return this.costModelElementService.getDropdownList(this.ciSundryItem.OwnerEntityId).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadVatRate(searchText: string) {
                if (this.ciSundryItem) {
                    return this.vatRateService.getDropdownList().query({ ownerEntityId: this.ciSundryItem.OwnerEntityId, countryId: this.ciSundryItem.SupplierCountryId }, () => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }



            loadApportionments() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CustomsApportionment
                }, () => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadUoMApportionments() {
                if (this.ciSundryItem && this.ciSundryItem.OwnerEntityId) {
                    return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.ciSundryItem.OwnerEntityId }, () => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            loadEntities(searchText: string) {
                if (this.ciSundryItem && this.ciSundryItem.OwnerEntityId) {
                    return this.entityService.getDropdownList(searchText, this.ciSundryItem.OwnerEntityId).query(
                        () => {
                        }, (failureData) => {
                        }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
               
            }

            loadDIs() {
                if (this.ciSundryItem && this.ciSundryItem.ConsignmentId) {
                    return this.commercialInvoiceService.getDILookupSearchByConsignmentId(this.ciSundryItem.ConsignmentId).query(() => { }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            loadWarehouses() {
                if (this.ciSundryItem) {
                    return this.warehouseService.getDropdownList().query({
                        ownerEntityId: this.ciSundryItem.OwnerEntityId
                    }, () => {
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            ALCApportionmentChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model.Id !== 404) {
                    this.ciSundryItem.ALCApportionmentUnitOfMeasure = null;
                }
            }

            sundryItemChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'cost.invoice.update'
                    },
                        () => {
                            return this.sundryItemService.getDefault().get({ sunId: model.Id, countryId: this.ciSundryItem.SupplierCountryId }, (result: interfaces.master.ISundryItemDefault) => {
                                this.ciSundryItem.IsDutiable = result.Dutiable;
                                this.ciSundryItem.IsIncludingInPrice = result.IsIncludedInPrice;

                                //Actual rate!
                                this.ciSundryItem.VATRate = result.VatRateRate;

                                //Dropdown value! Confusing I know.
                                this.ciSundryItem.VATRateRate = result.VatRate;

                                if (result.CostModelElement) {
                                    this.ciSundryItem.CostModelElement = result.CostModelElement;
                                }

                                if (result.ApportionmentClassificationValue) {
                                    this.ciSundryItem.ALCApportionmentClassificationValue = result.ApportionmentClassificationValue;
                                }

                                if (result.ApportionmentUnitOfMeasure && this.ciSundryItem.ALCApportionmentClassificationValue && this.ciSundryItem.ALCApportionmentClassificationValue.Id === 404) {
                                    this.ciSundryItem.ALCApportionmentUnitOfMeasure = result.ApportionmentUnitOfMeasure;
                                }

                                this.ciSundryItem.SundryItem.Description = result.Description;


                                if (this.ciSundryItem.SundryItem) {
                                    this.breadCrumbDesc = this.ciSundryItem.SequenceNumber + " - " + model.Description;
                                }

                                this.ciSundryItem.Quantity = 1;
                                this.ciSundryItem.SellingPrice = 0;
                                this.ciSundryItem.VATValue = 0;
                                this.ciSundryItem.SellingValue = 0;

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            quantityChanged() {
                this.calculateVat();
            }

            sellingPriceChanged() {
                this.calculateVat();
            }


            vatRateChanged(model: interfaces.applicationcore.IDropdownModel) {

                if (model && model.Id) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'ci.sun.update'
                    },
                        () => {
                            return this.vatRateService.getVatRate().get({ mvrId: model.Id }, (result: interfaces.master.IVatRate) => {
                                this.ciSundryItem.VATRate = result.CurrentRate;
                                this.calculateVat();
                            });
                        });
                } else {
                    this.ciSundryItem.VATRate = 0;
                    this.calculateVat();
                }

            }

            calculateVat() {
                this.ciSundryItem.SellingValue = Number((Math.round(this.ciSundryItem.Quantity * this.ciSundryItem.SellingPrice).toFixed(2)));
                if (this.ciSundryItem.IsZeroRated)
                    this.ciSundryItem.VATValue = 0;
                else
                    this.ciSundryItem.VATValue = Number((Math.round(this.ciSundryItem.SellingValue * this.ciSundryItem.VATRate) / 100));
            }

            saveCommercialInvoiceSundryItem() {
                return this.commercialInvoiceSundryItemService.save().save({}, this.ciSundryItem, (result) => {
                    this.generalService.displayMessageHandler(result);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            AddNewLine() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ci.sun.update'
                }, () => {
                    return this.saveCommercialInvoiceSundryItem().then((result) => {
                        if (!result.HasErrorMessage) {

                            this.$state.params["cisId"] = 0;
                            this.$state.reload();

                        }
                        this.$anchorScroll("topAnchor");
                    });
                });
            }

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ci.sun.update'
                }, () => {
                    return this.saveCommercialInvoiceSundryItem().then((result) => {
                        if (!result.HasErrorMessage) {
                            if(this.$state.params["cisId"]) {
                                this.$state.reload();
                            } else {
                                this.$state.params["cisId"] = Number(result.ID);
                                this.$state.go(this.$state.current.name, this.$state.params);
                            }
                        }
                        this.$anchorScroll("topAnchor");
                    });
                });
            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ci.sun.update'
                }, () => {
                    return this.saveCommercialInvoiceSundryItem().then((result) => {
                        if (!result.HasErrorMessage) {
                            this.$state.go("^");
                        }
                        this.$anchorScroll("topAnchor");
                    });
                });
            }

            Close() {
                this.$anchorScroll("topAnchor");
                this.$state.go("^");
            }
        }

        angular.module("app").controller("commercialInvoiceSundryItemCtrl", controllers.logistics.commercialInvoiceSundryItemCtrl);
    }
}